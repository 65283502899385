import * as React from "react";
import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import styled from "styled-components";
//import Graph from '../components/Graph';
import loadable from '@loadable/component';
import { useStaticQuery, graphql } from 'gatsby';
import '../styles/global.css';
import update from 'immutability-helper';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';


// todo: move to a shared layout component
import "@fontsource/roboto-condensed";
import "@fontsource/lato";

// wrapped in loadable as forcegraph contains window refs
//const Graph = loadable(() => import('../components/Graph'));
const Graph = loadable(() => import('../components/Graph2D'));

const Modes = { STORY: 'STORY', EXPLORE: 'EXPLORE' }

const Container = styled.div`
    background: linear-gradient(#000, #333);
    background-size:cover;
`;



const GraphPage = (props) => {

    const [mode, setMode] = useState(Modes.STORY);
    const [nodeTitle, setNodeTitle] = useState("");
    const [nodeType, setNodeType] = useState(null);
    const [nodeContent, setNodeContent] = useState("");
    const [nodeContentImages, setNodeContentImages] = useState([]);
    const [popupActive, setPopupActive] = useState(false);
    const [currentNode, setCurrentNode] = useState(-1);
    const [currentStory, setCurrentStory] = useState(-1);
    const [graphData, setGraphData] = useState(null);
    //const [storyIds, setStoryIds] = useState(["Vision_1", "Objective_3", "Foundation_2", "Initiative_1", "Beneficiary_1"]);
    const [storyData, setStoryData] = useState([]);
    const [storyMode, setStoryMode] = useState(false);
    const [rootId, setRootId] = useState(null);
    // get strapi data
    const data = useStaticQuery(graphql`
        query {
            allStrapiVision {
                nodes {
                id
                strapiId
                title
                content
                color
                objectives {
                    id
                }
                }
            }
            allStrapiObjective {
                nodes {
                id
                strapiId
                title
                content
                color
                foundations {
                    id
                }
                }
            }
            allStrapiFoundation {
                nodes {
                    id
                    strapiId
                    title
                    content
                    color
                    initiatives {
                        id
                    }
                    targets {
                        id
                    }
                    callouts {
                        id
                    }
                }
            }
            allStrapiInitiative {
                nodes {
                    id
                    strapiId
                    title
                    content
                    beneficiaries {
                        id
                    }                
                }
            }
            allStrapiCallout {
                nodes {
                    id
                    strapiId
                    title
                    content
                    content_images {                                            
                        base
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            allStrapiBeneficiary {
                nodes {
                    id
                    strapiId
                    title
                    content
                    initiatives {
                        id
                    }
                }
            }                     
            allStrapiTarget {
                nodes {
                    measurables {
                      id
                    }
                    id
                    title
                    content
                }
            }
            allStrapiMeasurable {
                nodes {
                    id
                    strapiId
                    title:Title
                    content:BodyCopy  
                    content_images: BodyCopy_images {                                            
                        base
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }                  
                }
            }
            allStrapiStory {
                nodes {
                  strapiId
                  title
                  id
                  vision {
                    id
                  }
                  objective {
                    id
                  }
                  foundation {
                    id
                  }
                  target {id}
                  measurable {id}
                  camera {
                    x
                    y
                    z
                  }
                }
              }
        }
    `);
    // const handleNodeChange = (node) => {
    //     console.log("root NODE CHANGE",node);
    //     setCurrentNode(node);
    // };
    const handleStoryChange = (id) => {
        console.log("STORY CHANGE", id);
        //setCurrentNode(storyData.find(x=>x.id==id).nodes[0]);
        setCurrentStory(id);
    };

    const handleSetStory = (s) => {
        setStoryMode(s);
    }

    /*
    **  NODE INDEX CHANGE
    */
    const handleNodeChange = (nodeId, resetAll = false, internal = true) => {
        console.log('changing node', nodeId);
        const ind = graphData.nodes.map(item => item.id).indexOf(nodeId);
        let changedState;
        // reset actives
        if (resetAll) {
            console.log('resetAll');
            setGraphData({
                ...graphData,
                nodes: graphData.nodes.map((node, i) => {
                    // console.log({
                    //     ...node,
                    //     collapsed: true,
                    //     isActive: false,
                    //     childLinks: []
                    // },i);
                    if (node.id == rootId)
                        return ({
                            ...node,
                            collapsed: false,
                            isActive: true
                        });
                    return ({
                        ...node,
                        collapsed: true,
                        isActive: false
                    });
                })
            });
            //changedState = update(graphData, { nodes: { [ind]: { collapsed: { $apply: x => !x }, isActive: { $apply: x => true }, childLinks: [] } } });
        } else {
            console.log('setting to active', graphData.nodes[ind]);
            //changedState = update(graphData, { nodes: { [ind]: { collapsed: { $apply: x => !x }, isActive: { $apply: x => true }, childLinks: [] } } });
            changedState = update(graphData, { nodes: { [ind]: { collapsed: { $apply: x => ind == 0 && storyMode ? false : !x }, isActive: { $apply: x => true }, childLinks: [] } } });
            setGraphData(changedState);
        }
        // text content
        const node = graphData.nodes[ind];
        console.log('node', node);
        // setNodeTitle(node.title);
        // setNodeContent(node.content);
        // setNodeType(node.group);
        // setNodeContentImages(node.content_images)
        if (internal)
            setCurrentNode(node);
        setPopupActive(true);


    };

    const extractNodeVals = (obj, type, childObjects) => {
        let node = {
            id: obj.id,
            type,
            title: obj.title,
            content: obj.content,
            collapsed: true,
            childLinks: [],
            isActive: false,
            color: obj.color && JSON.parse(obj.color).css || 'grey',
            content_images: obj.content_images || []
        };
        let links = [];
        if (childObjects) {
            for (const [key, value] of Object.entries(childObjects)) {
                value.forEach(x => {
                    let identifier = `${key}_${x.id}`;
                    links.push({ source: obj.id, target: identifier, value: x.value });
                    node.childLinks.push(identifier);
                });
            };
        }
        return { node, links };
    };

    /*
    **  BUILD THE SCHEMA
    */
    useEffect(() => {
        if (!data)
            return;
        let nodes = [];
        let links = [];

        data.allStrapiVision.nodes.forEach(vision => {
            setRootId(vision.id);
            let v = extractNodeVals(vision, "vision", { "Objective": vision.objectives });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiObjective.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "objective", { "Foundation": obj.foundations });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiFoundation.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "foundation", { "Initiative": obj.initiatives, "Target": obj.targets, "Callout": obj.callouts });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiInitiative.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "initiative", { "Beneficiary": obj.beneficiaries });
            nodes.push(v.node);
            links.push(...v.links);
        });
        data.allStrapiBeneficiary.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "beneficiary");
            nodes.push(v.node);
        });
        data.allStrapiCallout.nodes.forEach(obj => {
            nodes.push(extractNodeVals(obj, "callout").node);
        });
        data.allStrapiTarget.nodes.forEach(obj => {
            let v = extractNodeVals(obj, "target", { "Measurable": obj.measurables });
            nodes.push(v.node);
        });
        data.allStrapiMeasurable.nodes.forEach(obj => {
            nodes.push(extractNodeVals(obj, "measurable").node);
        });
        let ids = nodes.map(o => o.id);
        let filtered = nodes.filter(({ id }, index) => !ids.includes(id, index + 1));
        setGraphData({ nodes: filtered, links });

        // STORY DATA
        data?.allStrapiStory?.nodes?.forEach(x => {
            let snodes = [];
            // x.vision&&nodes.push(`Vision_${x.vision.id}`);
            // x.objective&&nodes.push(`Objective_${x.objective.id}`);
            // x.foundation&&nodes.push(`Foundation_${x.foundation.id}`);
            // x.callout&&nodes.push(`Callout_${x.callout.id}`);
            // x.target&&nodes.push(`Target_${x.target.id}`);
            // x.measurable&&nodes.push(`Measurable_${x.measurable.id}`);
            console.log('x', x);
            x.vision && snodes.push(nodes.find(y => y.id == `Vision_${x.vision.id}`));
            x.objective && snodes.push(nodes.find(y => y.id == `Objective_${x.objective.id}`));
            x.foundation && snodes.push(nodes.find(y => y.id == `Foundation_${x.foundation.id}`));
            x.callout && snodes.push(nodes.find(y => y.id == `Callout_${x.callout.id}`));
            x.target && snodes.push(nodes.find(y => y.id == `Target_${x.target.id}`));
            x.measurable && snodes.push(nodes.find(y => y.id == `Measurable_${x.measurable.id}`));
            x.nodes = snodes;
        });
        setCurrentStory(data.allStrapiStory?.nodes[0].id);
        setStoryData([...data.allStrapiStory.nodes]);
    }, [data]);


    useEffect(() => console.log(storyData), [storyData]);
    /*
    **  RENDER
    */
    return (
        <Container>
            <Graph
                setPopupActive={setPopupActive}
                // setNodeContent={setNodeContent} 
                // setNodeContentImages={setNodeContentImages}
                // setNodeTitle={setNodeTitle} 
                // setNodeType={setNodeType}
                //setCurrentNode={handleNodeChange}
                handleNodeChange={handleNodeChange}
                rootId={rootId}
                //data={data} 
                graphData={graphData}
                setGraphData={setGraphData}
                curStory={currentStory}
                storyData={storyData}
                storyMode={storyMode}
                curNode={
                    currentNode
                    //storyData.find(x => x.id == currentStory)?.nodes[currentNode.id]
                }
            />
            <Header
                storyMode={storyMode}
                setStoryMode={handleSetStory}
                storySelectChange={handleStoryChange}
                storyData={storyData}
            />
            <Sidebar
                //nodeTitle={nodeTitle} 
                //nodeContent={nodeContent} 
                //nodeContentImages={nodeContentImages}
                //nodeType={nodeType}
                popupActive={popupActive}
                handleNodeChange={handleNodeChange}

                currentNode={currentNode}
                currentStory={currentStory}
                storyData={storyData}
                storyMode={storyMode}
                setStoryMode={handleSetStory}
            />
        </Container>
    )
}
export default GraphPage